<template>
    <VRow no-gutters>
        <VCol cols="6">
            <SrHeadline weight="bold">
                {{ headlineName }}
            </SrHeadline>
        </VCol>

        <VCol cols="6" class="d-flex justify-end">
            <SrButton type="secondary" :disabled="disabled" :data-testid="dataTestIdForButton" @click="openCreateForm">
                {{ buttonName }}
            </SrButton>
        </VCol>

        <VCol cols="12">
            <slot name="filter" />
        </VCol>

        <VCol cols="4" class="pr-2">
            <slot name="search" />
        </VCol>
    </VRow>
</template>

<script>
import { SrButton, SrHeadline } from "@ads/design-system";

export default {
    components: { SrButton, SrHeadline },
    props: {
        headlineName: {
            type: String,
            required: true,
        },
        buttonName: {
            type: String,
            required: true,
        },
        route: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        dataTestIdForButton: {
            type: String,
            required: false,
            default: "",
        },
        params: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        openCreateForm() {
            this.$router.push({ name: this.route, params: this.params });
        },
    },
};
</script>
