<template>
    <VForm ref="form" v-model="isFormValid" class="dynamic-display-video-form" :disabled="isLoading">
        <VRow>
            <VCol class="ml-1">
                <SrHeadline level="3" class="mb-6" weight="bold">Details</SrHeadline>
                <VRow>
                    <VCol sm="12" lg="4">
                        <SrInput
                            v-model="video.name"
                            :rules="rules.name"
                            label="Video Name"
                            data-testid="video-name-input"
                            required
                        />
                    </VCol>
                </VRow>
                <VRow>
                    <VCol sm="12" lg="4" class="pt-0">
                        <SrSelect
                            v-model="video.businessEntity"
                            label="Advertiser"
                            data-testid="advertiser-select-dropdown"
                            :disabled="isEditing"
                            :items="advertisers"
                            item-value="id"
                            item-text="name"
                            required
                            searchable
                            searchable-property="name"
                            sort-order="ASC"
                            return-object
                        />
                    </VCol>
                </VRow>
                <VRow>
                    <VCol sm="12" lg="4" class="pt-0">
                        <SrSelect
                            v-model="video.rebuiltInterval"
                            label="Rebuilt interval (in minutes)"
                            data-testid="rebuilt-interval-select-dropdown"
                            :items="videoIntervals"
                            item-value="value"
                            item-text="label"
                            required
                        />
                    </VCol>
                </VRow>
            </VCol>
        </VRow>

        <VRow>
            <VCol sm="12" md="6">
                <CreativeTypeSelector
                    title="Select Video Type"
                    description="The process of creating a dynamic video and the available
                    features differ depending on the selected type."
                    :selected-type="video.type"
                    :readonly="isEditing"
                    :creative-types="creativeTypes"
                    @change="setType"
                />
            </VCol>
        </VRow>
    </VForm>
</template>

<script>
import { SrHeadline, SrInput, SrSelect } from "@ads/design-system";
import { textValidationRules } from "@/validation/rules";
import { mapGetters } from "vuex";
import CreativeTypeSelector from "@/components/CreativeTypeSelector/CreativeTypeSelector.vue";
import { creativeTypes, videoIntervals } from "@/modules/videos/components/VideoForm/VideoFormStepBasics/config";

export default {
    name: "VideoFormStepBasics",
    components: {
        CreativeTypeSelector,
        SrInput,
        SrSelect,
        SrHeadline,
    },
    inject: {
        isEditing: {
            default: false,
        },
    },
    model: {
        prop: "video",
        event: "input",
    },
    props: {
        video: {
            type: Object,
            required: true,
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            rules: {
                name: textValidationRules({
                    propertyName: "Video Name",
                    options: { min: 3, max: 128 },
                }),
            },
            isFormValid: this.isValid,
            creativeTypes,
        };
    },
    computed: {
        ...mapGetters({
            advertisers: "iam/businessEntities",
        }),
        affiliateId() {
            return this.video.businessEntity?.dspId.toString();
        },
        videoIntervals() {
            return videoIntervals[this.video.type];
        },
    },
    watch: {
        video() {
            this.$emit("update:video", this.video);
        },
        isFormValid(isValid) {
            this.$emit("update:isValid", isValid);
        },
    },
    created() {
        if (!this.video.rebuiltInterval) {
            const DEFAULT_REBUILT_INTERVAL = 120;
            this.video.rebuiltInterval = DEFAULT_REBUILT_INTERVAL;
        }
    },
    methods: {
        setType(type) {
            if (creativeTypes[type].disabled) {
                return;
            }

            this.$set(this.video, "type", type);
        },
    },
};
</script>

<style lang="scss">
.dynamic-display-video-form {
    .required-indicator {
        color: $brand-red;
        margin-left: 4px;
    }
}
</style>
