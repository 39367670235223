<template>
    <SrModalPageForm
        :show.sync="show"
        class="modal-form stepper-form"
        :steps="stepMetaData"
        :loading="isLoading"
        @close="close"
        @submit="submit"
        @input="onStepChange"
    >
        <SrModalPageStep v-for="(step, index) in steps" :key="index" class="mb-4" :step="index + 1" :title="step.name">
            <keep-alive>
                <component
                    :is="step.component"
                    v-if="video"
                    :is-valid.sync="step.isValid"
                    :video="video"
                    :is-loading="isLoading"
                    @image-change="onImageChange"
                />
            </keep-alive>
        </SrModalPageStep>

        <template #footer>
            <ErrorBox :show="showErrorMessage" :message="error" />
        </template>
    </SrModalPageForm>
</template>

<script>
import { SrModalPageForm, SrModalPageStep } from "@ads/design-system";
import BasicForm from "@/components/BasicForm";
import ErrorBox from "@/components/ErrorBox";
import { mapActions, mapGetters } from "vuex";
import { LOADING_STATES } from "@/modules/videos/types";
import { LOADING_STATES as IAM_LOADING_STATES } from "@/modules/iam/store/state";
import { s3PresignedPostUploadService } from "@/modules/videos/services/S3PresignedPostUploadService";
import { formSteps, getDefaultConfig } from "./config";
import VideoFormStepBasics from "./VideoFormStepBasics";
import VideoFormStepUpload from "./VideoFormStepUpload";

export default {
    name: "VideoForm",
    components: {
        SrModalPageForm,
        SrModalPageStep,
        ErrorBox,
        VideoFormStepBasics,
        VideoFormStepUpload,
    },
    extends: BasicForm,
    inject: {
        isEditing: {
            default: false,
        },
    },
    data() {
        return {
            show: true,
            video: this.value,
            steps: formSteps,
            error: null,
            currentStep: 1,
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            iamLoadingStates: "iam/loadingStates",
            loadingStates: "videos/loadingStates",
            layouts: "videos/layouts",
        }),
        isLoading() {
            return (
                this.loadingStates(LOADING_STATES.CREATE) ||
                this.loadingStates(LOADING_STATES.AD_BUILDER_TEMPLATES) ||
                this.iamLoadingStates(IAM_LOADING_STATES.BUSINESS_ENTITIES) ||
                this.loading
            );
        },
        stepMetaData() {
            return this.steps.map((step) => ({
                name: step.name,
                canContinue: step.isValid,
            }));
        },
        showErrorMessage() {
            return Boolean(this.error);
        },
    },
    watch: {
        video(video) {
            this.$emit("input", video);
        },
        value(video) {
            this.video = video;
        },
        layouts() {
            if (!this.video.layout) {
                this.setDefaultLayout();
            }
        },
        "video.type": function (type) {
            this.video = {
                ...getDefaultConfig(type),
                ...this.video,
            };

            this.setDefaultLayout();
        },
    },
    async created() {
        await this.fetchAdvertisers();
        await this.fetchLayouts();
    },
    methods: {
        ...mapActions({
            fetchBusinessEntities: "iam/fetchBusinessEntities",
            fetchLayouts: "videos/fetchVideoLayouts",
        }),
        setDefaultLayout() {
            const newLayout = this.layouts.find((layout) => layout.type === this.video.type);
            this.$set(this.video, "layout", newLayout);
        },
        async fetchAdvertisers() {
            try {
                await this.fetchBusinessEntities();
            } catch (error) {
                this.error = error.message || error.response.message;
            }
        },
        async save() {
            const { file, backgroundImage, willUploadBackgroundImage, ...video } = this.video;

            this.setFlagsOnVideoRequest(file, video, backgroundImage, willUploadBackgroundImage);

            const {
                presignedPost,
                presignedPostBackgroundImage,
                video: savedVideo,
            } = await this.$store.dispatch(this.submitAction, video);

            if (file) {
                await s3PresignedPostUploadService.upload(file, presignedPost.url, presignedPost.fields);
            }
            if (backgroundImage) {
                await s3PresignedPostUploadService.upload(
                    backgroundImage,
                    presignedPostBackgroundImage.url,
                    presignedPostBackgroundImage.fields,
                );
            }

            const shouldUpdateStatus = (!video.id || file || backgroundImage) && savedVideo;
            if (shouldUpdateStatus) {
                video.id = savedVideo.id;
                video.willUploadBackgroundImage = false; // we have already uploaded those on the previous step
                video.willUploadVideo = false;
                await this.$store.dispatch("videos/edit", video);
            }
        },
        onStepChange(currentStep) {
            this.currentStep = currentStep;
        },
        setImage(value, name) {
            this.$set(this.video, name, value);
        },
        imageToBase64(image, name) {
            if (!image) {
                return this.setImage(null, name);
            }

            if (image.type === "mock") {
                return this.setImage(undefined, name);
            }

            return this.setImage(image, name);
        },
        onImageChange({ file, name }) {
            this.imageToBase64(file, name);
        },
        setFlagsOnVideoRequest(file, video, backgroundImage, willUploadBackgroundImage) {
            if (file) {
                video.willUploadVideo = true;
            } else {
                video.willUploadVideo = false;
            }

            if (backgroundImage && backgroundImage.type !== "mock" && willUploadBackgroundImage) {
                video.willUploadBackgroundImage = true;
                video.willRemoveBackgroundImage = false;
                video.backgroundImageName = backgroundImage.name;
            } else {
                video.willUploadBackgroundImage = false;
                video.willRemoveBackgroundImage = false;
                if (this.isEditing && backgroundImage === null) {
                    video.willRemoveBackgroundImage = true;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.stepper-form {
    .v-stepper__header {
        max-width: 400px;
    }
}
</style>
