import { ACTION_NAME, IRequestOptions, RequestService } from "@ads/iam-library";
import { API_URL } from "@/config";
import { FetchUrlResponse, Video, VIDEO_TYPE, VideoLayout } from "../types";

export interface IVideoCreateRequest {
    name: string;
    businessEntity: number;
    type: VIDEO_TYPE;
    layout: number | VideoLayout;
    filterId?: number;
    productSetId?: string;
    templateId?: string;
}

export interface IVideoEditRequest {
    id: number;
    name: string;
    layout: number | VideoLayout;
    filterId?: number;
    productSetId?: string;
    templateId?: string;
}

export default class VideosService {
    private readonly defaultRequestOptions: Pick<IRequestOptions, "domain" | "resource"> = {
        domain: "dynamic-display",
        resource: "video-creative",
    };

    private readonly requestService: RequestService;

    private readonly domainPath: string;

    constructor(requestService: RequestService, domainPath?: string) {
        this.requestService = requestService;
        this.domainPath = domainPath;
    }

    private getRequestOptions(options: Partial<IRequestOptions>): IRequestOptions {
        return {
            ...this.defaultRequestOptions,
            ...options,
        } as IRequestOptions;
    }

    fetchVideos(): Promise<Video[]> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });
        return this.requestService.get(`/${this.domainPath}`, requestOptions);
    }

    fetchVideo(id: number): Promise<Video> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });
        return this.requestService.get(`/${this.domainPath}/${id}`, requestOptions);
    }

    createVideo(video: IVideoCreateRequest): Promise<Video> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.CREATE });
        return this.requestService.post(`/${this.domainPath}`, video, requestOptions);
    }

    editVideo(video: IVideoEditRequest): Promise<Video> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.EDIT });
        return this.requestService.put(`/${this.domainPath}`, video, requestOptions);
    }

    viewDynamicVideo(id: number): Promise<FetchUrlResponse> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });
        return this.requestService.get(`/${this.domainPath}/getUrlForDynamicVideoPreview/${id}`, requestOptions);
    }

    viewOriginalVideo(id: number): Promise<FetchUrlResponse> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.READ });
        return this.requestService.get(`/${this.domainPath}/getUrlForOriginalVideoPreview/${id}`, requestOptions);
    }

    markForDelete(id: number): Promise<Video> {
        const requestOptions = this.getRequestOptions({ action: ACTION_NAME.EDIT });
        return this.requestService.put(`/${this.domainPath}/markForDelete/${id}`, null, requestOptions);
    }
}

export const videosService = new VideosService(new RequestService({ baseUrl: API_URL }), "videos");
