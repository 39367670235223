<template>
    <div class="video-upload" data-testid="video-upload">
        <FileUpload
            v-model="internalFile"
            :accept="accept"
            :display-file="false"
            v-bind="$attrs"
            display-unit="MB"
            :rules="validationRules"
            @change="onChange"
            @delete="onDelete"
        >
            <template #default="{ triggerUpload, removeFile, hasFile, error }">
                <div v-if="hasFile && !error" class="file-upload-fullscreen-preview">
                    <video :src="previewUrl" controls class="video" :style="{ aspectRatio }" />
                    <div class="button-container">
                        <SrButton class="button" elevation="1" small type="delete" @click="removeFile"> Delete </SrButton>
                    </div>
                </div>
                <div v-else class="file-upload-fullscreen-trigger" :style="{ aspectRatio }" @click="triggerUpload">
                    <SrIcon icon="upload" size="l" />
                    <div class="file-upload-fullscreen-trigger-hint">Drag and Drop file here or click to upload</div>
                </div>
            </template>
        </FileUpload>
    </div>
</template>
<script>
import FileUpload from "@/components/FileUpload/FileUpload.vue";
import { SrButton, SrIcon } from "@ads/design-system";
import { MAX_VIDEO_SIZE } from "@/modules/videos/components/VideoForm/config";
import { s3PresignedGetFetchService } from "@/modules/videos/services/S3PresignedGetFetchService";

export default {
    name: "VideoUpload",
    components: { FileUpload, SrButton, SrIcon },
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        size: {
            type: Object,
            default: () => ({}),
        },
        value: {
            type: File,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        accept: {
            type: String,
            default: "video/*",
        },
        isValid: {
            type: Boolean,
            default: false,
        },
        aspectRatio: {
            type: String,
            default: "3 / 2",
        },
        presignedVideoUrl: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            internalFile: null,
            previewUrl: null,
        };
    },
    computed: {
        validationRules() {
            return [
                (v) => !!v || "File is required",
                (v) => !v || v.size < MAX_VIDEO_SIZE || "File size should be less than 30 MB",
                (v) => {
                    if (!v) {
                        return true;
                    }

                    if (this.accept === "video/*") {
                        return v.type.includes("video") || "File must be a video";
                    }

                    const types = this.accept.split(",");
                    return (
                        types.includes(v.type) || `File type is not allowed. Allowed types: ${this.accept.replace("video/", " ")}`
                    );
                },
            ];
        },
    },
    watch: {
        value() {
            if (!this.internalFile || this.presignedVideoUrl) {
                this.setFile();
            }
        },
        presignedVideoUrl() {
            if (!this.internalFile || this.presignedVideoUrl) {
                this.setFile();
            }
        },
    },
    created() {
        this.setFile();
    },
    methods: {
        onChange(file) {
            this.$emit("input", file);
            if (file) {
                this.previewUrl = URL.createObjectURL(file);
            }
        },
        onDelete() {
            this.previewUrl = null;
            this.internalFile = null;
        },
        setFile() {
            if (this.value) {
                this.internalFile = this.createMockFile(`${this.name}.mp4`);
            } else if (this.presignedVideoUrl) {
                s3PresignedGetFetchService
                    .download(this.presignedVideoUrl)
                    .then((file) => {
                        this.internalFile = file;
                        this.previewUrl = URL.createObjectURL(file);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        createMockFile(name) {
            return new File([], name, { type: "mock" });
        },
    },
};
</script>

<style lang="scss">
@import "~@ads/design-system/src/scss/variables";

.video-upload {
    height: 100%;
    width: 100%;
    user-select: none;
    cursor: pointer;

    .file-upload {
        background-color: #fff;
        height: 100%;
        width: 100%;
        border-radius: 3px;
        overflow: hidden;
        border: none;

        .v-messages__message {
            position: absolute;
            bottom: 3px;
            text-align: center;
            display: flex;
            width: 100%;
            justify-content: center;

            span {
                background-color: rgba(224, 171, 171, 0.3);
                padding: 2px 4px 1px;
                border-radius: 3px;
            }
        }
    }

    .video-upload-error {
        background-color: unset;
        color: $brand-red;
        text-align: center;
        margin-top: 6px;
    }

    .file-upload-fullscreen-trigger {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 3px dashed #6098e7;
        padding: 40px 30px;
        border-radius: 3px;

        &-hint {
            color: #9f9f9f;
            font-size: 14px;
            font-weight: 300;
        }
    }

    .file-upload-fullscreen-preview {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .video {
            width: 100%;
            border-radius: 3px;
            background-color: rgba(0, 0, 0, 0.2);
        }

        .button-container {
            width: 100%;
            text-align: right;
            margin-top: 10px;
        }
    }
}
</style>
